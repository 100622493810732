import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 *  分页查询
 * @param params
 */
export function getTableList(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/code/manageCompany/page',
    method: 'get',
    params,
  });
}

/**
 *  删除
 * @param params
 */
export function getDelete(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/code/manageCompany/delete',
    method: 'get',
    params,
  });
}

/**
 *  新增/编辑
 * @param params
 */
export function addTable(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/code/manageCompany/add',
    method: 'post',
    data:params,
  });
}

/**
 *  弹窗-查看详情
 * @param params
 */
export function getDetail(params) {
  return request({
    url: envInfo.bgApp.amsPath + '/code/manageCompany/detail',
    method: 'get',
    params,
  });
}