<template>
    <div>
        <div class="button-line">
            <r-button plain @click="addNewConfig">新增</r-button>
            <r-button @click="initDatas">刷新</r-button>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler">
                <template slot='operating' slot-scope="scope">
                    <div v-if="scope.datas.editable == false">
                        <el-tooltip effect="dark" placement="left">
                            <div slot="content">已生成资产编码数据，不可操作。</div>
                            <div class="spanWhite">
                                <span>编辑</span><span style="margin-left: 20px;">删除</span>
                            </div>
                        </el-tooltip>
                    </div>
                    <div v-else-if="scope.datas.editable == true">
                        <span @click="editConfig(scope.datas)" class="spanEdit">编辑</span>
                        <span @click="deleteConfig(scope.datas)" class="spanDelete">删除</span>
                    </div>
                </template>
            </finalTable>
        </div>
        <el-dialog :visible.sync="detailDialogShow" width="728px" :destroy-on-close="true"
            :close-on-click-modal="false">
            <template slot="title">
                <span>新增</span>
            </template>
            <regionDialog :updateId="id" v-if="detailDialogShow" @close="closeDialog" />
        </el-dialog>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import regionDialog from "./regionDialog.vue"
import {
    getTableList, getDelete
} from "@/api/ruge/ams/basicConfig/regionCode";

export default {
    name: "ams-regionCode",
    components: {
        finalTable,
        regionDialog
    },
    data() {
        return {
            loadingFlag: false,
            detailDialogShow: false,
            id: null,
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                selection: {
                    need: false,
                },
                header: [
                    { prop: "areaCode", label: "代码", width: "" },
                    { prop: "areaName", label: "名称", width: "" },
                    { prop: "operation", label: "操作", width: "150" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    areaCode: {
                        type: "input",
                        label: "代码",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入代码",
                        prefixIcon: "el-icon-search",
                    },
                    areaName: {
                        type: "input",
                        label: "名称",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入名称",
                        prefixIcon: "el-icon-search",
                    },
                },
                // 表格内容配置
                detailConfig: {
                    // operation: {
                    //     type: "icon",
                    //     iconList: [
                    //         {
                    //             actionType: "iconClick",
                    //             eventName: "edit",
                    //             fontSize: "14px",
                    //             color: "#1A4CEC",
                    //             cursorPointer: "pointer",
                    //             iconName: "el-icon-edit",
                    //             tooltips: "编辑",
                    //         },
                    //         {
                    //             actionType: "iconClick",
                    //             eventName: "delete",
                    //             fontSize: "14px",
                    //             color: "#E47470",
                    //             cursorPointer: "pointer",
                    //             iconName: "el-icon-delete",
                    //             tooltips: "删除",
                    //         },

                    //     ],
                    // },
                    operation: {
                        type: "slot",
                        slotName: "operating",
                    },
                },
                // 高级搜索配置
                advanceFilterConfig: {
                    areaCode: {
                        inline: true,
                        value: "",
                    },
                    areaName: {
                        inline: true,
                        value: "",
                    },
                },
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            filterObj: {
                current: 1,
                rowCount: 10,
                areaCode: "",
                areaName: ""
            },
        };
    },
    watch: {
        loadingFlag: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val);
            },
        },
    },
    created() {
        this.initDatas();
    },
    methods: {
        closeDialog() {
            this.detailDialogShow = false;
            this.initDatas();
        },
        addNewConfig() {
            this.id = null
            this.detailDialogShow = true;
        },
        initDatas() {
            this.loadingFlag = true;
            getTableList(this.filterObj)
                .then((res) => {
                    this.dataset.tableData = res.data.rows;
                    this.dataset.pageVO.total = res.data.totalPages;
                    console.log("this.dataset.tableData", this.dataset.tableData)

                })
                .finally(() => {
                    this.loadingFlag = false;
                });
        },
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.filterObj.current = 1;
                this.filterObj = { ...this.filterObj, ...datas.params };
                this.initDatas();
            } else if (datas.type === "paginationChange") {
                this.filterObj.current = datas.params.current.page;
                this.filterObj.rowCount = datas.params.current.limit;
                this.initDatas();
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "delete":
                        this.deleteConfig(datas.row);
                        break;
                    case "edit":
                        this.editConfig(datas.row);
                        break;
                }
            }
        },
        editConfig(rowDatas) {
            this.detailDialogShow = true
            this.id = rowDatas.id
        },
        async deleteConfig(rowDatas) {
            await this.$confirm(`是否确认删除`, this.$t("commons.warning"), {
                confirmButtonText: this.$t("commons.confirm"),
                cancelButtonText: this.$t("commons.cancel"),
                type: "warning",
            });
            getDelete({
                id: rowDatas.id
            }).then(() => {
                this.$message.success("删除成功！");
                this.initDatas();
            });
        },
    },
};
</script>

<style lang="less" scoped>
.mini-evaluation-management {
    .button-line {
        margin-bottom: 10px;
    }
}

.spanEdit {
    color: #2A61FF;
    cursor: pointer;
}

.spanDelete {
    margin-left: 20px;
    color: #F35555;
    cursor: pointer;
}

.spanWhite {
    color: #B5BECE;
    cursor: pointer;
}
</style>