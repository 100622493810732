import { render, staticRenderFns } from "./accountCompany.vue?vue&type=template&id=13124dfa&scoped=true"
import script from "./accountCompany.vue?vue&type=script&lang=js"
export * from "./accountCompany.vue?vue&type=script&lang=js"
import style0 from "./accountCompany.vue?vue&type=style&index=0&id=13124dfa&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13124dfa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\ruge-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13124dfa')) {
      api.createRecord('13124dfa', component.options)
    } else {
      api.reload('13124dfa', component.options)
    }
    module.hot.accept("./accountCompany.vue?vue&type=template&id=13124dfa&scoped=true", function () {
      api.rerender('13124dfa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ruge/ams/basicConfig/tabCodeConfig/components/accountCompany.vue"
export default component.exports