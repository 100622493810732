<template>
    <div class="guide-form-comp">
        <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" label-width="100px"
            class="demo-ruleForm">
            <el-row>
                <el-col>
                    <el-form-item label="代码" prop="accountCompanyCode">
                        <el-input maxlength="50" placeholder="请输入代码" v-model="ruleForm.accountCompanyCode"
                            :maxlength="50" show-word-limit></el-input></el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item label="名称" prop="accountCompany">
                        <el-input maxlength="50" placeholder="请输入名称" v-model="ruleForm.accountCompany" :maxlength="50"
                            show-word-limit></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
           
            <el-row>
                <el-form-item align="right">
                    <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick>确定</el-button>
                    <el-button @click="resetForm">取消</el-button>
                </el-form-item>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {
    addTable, getDetail
} from "@/api/ruge/ams/basicConfig/accountCompany";
export default {
    props: {
        updateId: {
            type: Number,
            default: () => null
        },
    },
    data() {
        return {
            ruleForm: {
                accountCompanyCode: null,
                accountCompany: null,
            },
            rules: {
                accountCompanyCode: [
                    { required: true, message: "编码名称为必填", trigger: "blur" },
                ],
                accountCompany: [
                    { required: true, message: "编码为必填", trigger: "blur" },
                ],
            },
        };
    },
    created() {
        if (this.updateId) {
            this.findDetail()
        }
    },
    methods: {
        findDetail() {
            getDetail({
                id: this.updateId
            }).then((res) => {
                if (res.data) {
                    this.ruleForm.accountCompanyCode = res.data.accountCompanyCode
                    this.ruleForm.accountCompany = res.data.accountCompany
                    console.log("this.ruleForm",this.ruleForm)
                }
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitHandler();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        submitHandler() {
            addTable({
                id: this.updateId,
                accountCompanyCode: this.ruleForm.accountCompanyCode,
                accountCompany: this.ruleForm.accountCompany,
            }).then((res) => {
                if (res.code === 200) {
                    this.$message.success("操作成功！");
                    this.$emit("close", true);
                } else {
                    this.$message.warning("操作失败！");
                }
            });
        },
        resetForm() {
            this.$emit("close", false);
        },

    },
};
</script>

<style scoped lang="less"></style>