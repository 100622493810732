<template>
    <div class="app-container mini-evaluation-management">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="管理主体公司代码" name="first">
                <manageCompany></manageCompany>
            </el-tab-pane>
            <el-tab-pane label="核算主体公司代码" name="second">
                <accountCompany></accountCompany>
            </el-tab-pane>
            <el-tab-pane label="地区代码" name="third">
                <regionCode></regionCode>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import manageCompany from "./components/manageCompany.vue"
import accountCompany from "./components/accountCompany.vue"
import regionCode from "./components/regionCode"
export default {
    components: {
        manageCompany,
        accountCompany,
        regionCode
    },
    data() {
        return {
            activeName: 'first'
        };
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        }
    }
};
</script>
<style scoped lang="less"></style>